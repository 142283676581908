import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from '../../services/firebase.service';
import { HttpClient } from '@angular/common/http';
import { Lightbox } from 'ngx-lightbox';
import { Router, NavigationEnd } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';


@Component({
  selector: 'app-housedetail',
  templateUrl: './housedetail.component.html',
  styleUrls: ['./housedetail.component.scss']
})
export class HouseDetailComponent implements OnInit {
  id_house: String;
  private sub: any;
  itens: any;
  itensImagens: Array<any>;
  inputNome: String;
  inputEmail: String;
  inputTelefone: String;
  inputMensagem: String;
  url: string;
  private _albums: Array<any> = [];

  constructor(private route: ActivatedRoute,
    public firebaseService: FirebaseService, private httpClient: HttpClient, private _lightbox: Lightbox,
    private firestore: AngularFirestore, private router: Router) {

  }

  onSubmit() {
    if(this.inputNome == undefined){
      this.inputNome = "";
    }
    if(this.inputEmail == undefined){
      this.inputEmail = "";
    }
    if(this.inputTelefone == undefined){
      this.inputTelefone = "";
    }
    if(this.inputMensagem == undefined){
      this.inputMensagem = "";
    }

    this.url = "https://portoalegr16.sslblindado.com/util/sendmail.php?nome=" + this.inputNome
      + "&email=" + this.inputEmail
      + "&telefone=" + this.inputTelefone
      + "&mensagem=" + this.inputMensagem
      + "&cod_imovel=" + this.itens.payload.data().id_imovel;

    window.open(this.url, "_self");

    /*this.httpClient.get(this.url).subscribe((res) => {
      console.log(res);
    });*/
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    this.sub = this.route.params.subscribe(params => {
      this.id_house = params['id'];
    });

    this.firebaseService.getHomeDetail(this.id_house)
      .subscribe(result => {
        this.itens = result;
        this.itensImagens = this.itens.payload.data().imagens;

        for (let i = 0; i <= this.itensImagens.length; i++) {
          const src = this.itensImagens[i].path;
          const caption = '';
          const thumb = this.itensImagens[i].path;
          const album = {
            src: src,
            caption: caption,
            thumb: thumb
          };

          this._albums.push(album);
        }
      });
  }

  doTextareaValueChange(ev) {
    try {
      this.inputMensagem = ev.target.value;
    } catch(e) {
      console.info('could not set textarea-value');
    }
  }

  verificaThumb(thumb: String): String {
    if (thumb == null || thumb == '') {
      return "https://firebasestorage.googleapis.com/v0/b/portoalegrense-50728.appspot.com/o/sem_imagem.jpg?alt=media&token=a0b3cae5-8212-4773-ac11-c13d5ed6bdf3";
    } else {
      return thumb;
    }
  }

  convertValor(valor: String) {
    if (valor != "") {
      return "R$" + valor;
    } else {
      return "Sob consulta";
    }
  }

  convertTipo(id_tipo: String): String {
    switch (id_tipo) {
      case '1':
        return "Apartamento";
      case '2':
        return "Casa";
      case '3':
        return "Casa Comercial";
      case '4':
        return "Casa Geminada";
      case '5':
        return "Chácara";
      case '6':
        return "Cobertura";
      case '7':
        return "Kitnete";
      case '8':
        return "Loja";
      case '9':
        return "Pavilhão";
      case '10':
        return "Pousada";
      case '11':
        return "Prédio Comercial";
      case '12':
        return "Sala";
      case '13':
        return "Sítio";
      case '14':
        return "Terreno";
      case '15':
        return "Terreo Comercial";
      case '16':
        return "Terreno em condominio";
      case '17':
        return "Terrano em loteamento";
      default: "";
    }
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  isShow: boolean;
  topPosToStartShowing = 100;

  @HostListener('window:scroll')
  checkScroll() {

    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    console.log('[scroll]', scrollPosition);

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
