import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(public db: AngularFirestore) {}


  getAvatars(){
      return this.db.collection('/avatar').valueChanges()
  }

  getUser(userKey){
    return this.db.collection('users').doc(userKey).snapshotChanges();
  }

  updateUser(userKey, value){
    value.nameToSearch = value.name.toLowerCase();
    return this.db.collection('users').doc(userKey).set(value);
  }

  deleteUser(userKey){
    return this.db.collection('users').doc(userKey).delete();
  }

  deleteImovel(id){
    return this.db.collection('imoveis').doc(id).delete();
  }


  getTodosImoveis(){
    return this.db.collection('/imoveis').snapshotChanges();
  }

  getHomeAlugueisTemporada(){
    return this.db.collection('/imoveis', ref => ref.where("id_categoria", "==", "AT").limit(4)).snapshotChanges();
  }

  getListAlugueisTemporada(){
    return this.db.collection('/imoveis', ref => ref.where("id_categoria", "==", "AT")).snapshotChanges();
  }

  getHomeAlugueisAnual(){
    return this.db.collection('/imoveis', ref => ref.where("id_categoria", "==", "AA").limit(4)).snapshotChanges();
  }

  getListAlugueisAnual(){
    return this.db.collection('/imoveis', ref => ref.where("id_categoria", "==", "AA")).snapshotChanges();
  }

  getHomeLancamentos(){
    return this.db.collection('/imoveis', ref => ref.where("id_categoria", "==", "LA").limit(4)).snapshotChanges();
  }

  getListLancamentos(){
    return this.db.collection('/imoveis', ref => ref.where("id_categoria", "==", "LA")).snapshotChanges();
  }

  getHomeImoveisVenda(){
    return this.db.collection('/imoveis', ref => ref.where("id_categoria", "==", "VE").limit(4)).snapshotChanges();
  }

  getListImoveisVenda(){
    return this.db.collection('/imoveis', ref => ref.where("id_categoria", "==", "VE")).snapshotChanges();
  }

  getListaImagens(cod_imovel){
    return this.db.collection('/imagens', ref => ref.where("codigo_imovel", "==", cod_imovel)).snapshotChanges();
  }

  getPesquisaCodigo(id:String){
    return this.db.collection('/imoveis', ref => ref.where("id_imovel", "==", id)).snapshotChanges();
  }

  /*ref => ref.where("tipo", "==", tipo).where("negocio", "==", negocio)*/
  getPesquisaSimples(tipo, negocio, quartos, garagem, banheiros){

    banheiros = banheiros.replace(/\D/g,'');
    garagem = garagem.replace(/\D/g,'');
    quartos = quartos.replace(/\D/g,'');

    if(quartos == 'NULL'){
      return this.db.collection('/imoveis', ref => ref.where("id_categoria", "==", negocio).where("tipo", "==", tipo)
      .where("dormitorio", "==", quartos).where("garagem", "==", garagem).where("banheiro", "==", banheiros)).snapshotChanges();
    }else{
      return this.db.collection('/imoveis', ref => ref.where("id_categoria", "==", negocio).where("tipo", "==", tipo)).snapshotChanges();
    }
  }

  getHomeDetail(id_house){
    return this.db.collection('imoveis').doc(id_house).snapshotChanges();
  }

  getMaxCodigo(){
    return this.db.collection('/imoveis', ref => ref.orderBy('id_imovel','desc').limit(1)).snapshotChanges();
  }

  searchUsers(searchValue){
    return this.db.collection('users',ref => ref.where('nameToSearch', '>=', searchValue)
      .where('nameToSearch', '<=', searchValue + '\uf8ff'))
      .snapshotChanges()
  }

  searchUsersByAge(value){
    return this.db.collection('users',ref => ref.orderBy('age').startAt(value)).snapshotChanges();
  }


  createUser(value, avatar){
    return this.db.collection('users').add({
      name: value.name,
      nameToSearch: value.name.toLowerCase(),
      surname: value.surname,
      age: parseInt(value.age),
      avatar: avatar
    });
  }
}
