import { Component, OnInit, Input } from '@angular/core';
import { FirebaseService } from '../../services/firebase.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { catchError, finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { AuthService } from "../../core/auth.service";

@Component({
  selector: 'app-cadastrar-imovel',
  templateUrl: './cadastrar_imovel.component.html',
  styleUrls: ['./cadastrar_imovel.component.scss']
})
export class CadastrarImovelComponent implements OnInit {

  id_house: string;
  private sub: any;
  itens: any;

  inputTitulo: String;
  inputCodigo: String;
  inputValor: String;
  inputBairro: String;
  inputDescricao: String;
  inputInfraestrutura: String;
  inputDormitorios: String;
  inputBanheiros: String;
  inputGaragem: String;
  inputAcomodacoes: String;
  inputMUtil: String;
  inputMTotal: String;
  inputValorFestas: String;
  inputValorJaneiro: String;
  inputValorFevereiro: String;
  inputMaxPessoas: String;
  inputOcupacao: String;
  selectedThumb: String;
  inputProprietario: String;
  inputCidade: String;
  inputContatoCelular: String;
  inputContatoFixo: String;
  inputEnderecoImovel: String;

  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  imageUrl: String;
  itensImagens: Array<any>;
  selectedTipo;
  selectedNegocio;

  itensTipo: any[] = [
    { id: 1, tipo: 'Apartamento' },
    { id: 2, tipo: 'Casa' },
    { id: 3, tipo: 'Casa Comercial' },
    { id: 4, tipo: 'Casa Geminada' },
    { id: 5, tipo: 'Chácara' },
    { id: 6, tipo: 'Cobertura' },
    { id: 7, tipo: 'Kitnete' },
    { id: 8, tipo: 'Loja' },
    { id: 9, tipo: 'Pavilhão' },
    { id: 10, tipo: 'Pousada' },
    { id: 11, tipo: 'Prédio Comercial' },
    { id: 12, tipo: 'Sala' },
    { id: 13, tipo: 'Sítio' },
    { id: 14, tipo: 'Terreno' },
    { id: 15, tipo: 'Terreo Comercial' },
    { id: 16, tipo: 'Terreno em condominio' },
    { id: 17, tipo: 'Terrano em loteamento' },
  ];

  itensNegocio: any[] = [
    { id: 1, negocio: 'Aluguel Anual' },
    { id: 2, negocio: 'Aluguel Temporada' },
    { id: 3, negocio: 'Empreendimento' },
    { id: 4, negocio: 'Venda' }
  ];

  itensBairros: any[] = [
    { id: 1, negocio: 'Aluguel Anual' },
    { id: 2, negocio: 'Aluguel Temporada' },
    { id: 3, negocio: 'Empreendimento' },
    { id: 4, negocio: 'Venda' }
  ];

  constructor(private route: ActivatedRoute, private router: Router,
    public firebaseService: FirebaseService, private afStorage: AngularFireStorage, public db: AngularFirestore,
    public authService: AuthService) {
  }

  onSubmit() {
    if (this.inputTitulo == null) {
      alert("Informe um título!");
    } else if (this.inputCodigo == null) {
      alert("Informe um código!");
    } else if (this.selectedTipo == null) {
      alert("Informe o tipo do imóvel!");
    } else if (this.selectNegocio == null) {
      alert("Informe o negócio a ser feito!");
    } else {
      let id = this.db.createId();

      this.db.collection("imoveis").doc(id).set({
        titulo: this.inputTitulo != null ? this.inputTitulo : "",
        id_imovel: this.inputCodigo != null ? String(this.inputCodigo) : "",
        valor: this.inputValor != null ? this.inputValor.replace("R$", "") : "",
        bairro: this.inputBairro != null ? this.inputBairro : "",
        descricao: this.inputDescricao != null ? this.inputDescricao : "",
        infraestrutura: this.inputInfraestrutura != null ? this.inputInfraestrutura : "",
        dormitorio: this.inputDormitorios != null ? this.inputDormitorios : "",
        banheiro: this.inputBanheiros != null ? this.inputBanheiros : "",
        garagem: this.inputGaragem != null ? this.inputGaragem : "",
        acomodacoes: this.inputAcomodacoes != null ? this.inputAcomodacoes : "",
        mutil: this.inputMUtil != null ? this.inputMUtil : "",
        mtotal: this.inputMTotal != null ? this.inputMTotal : "",
        pr_festas: this.inputValorFestas != null ? this.inputValorFestas.replace("R$", "") : "",
        pr_janeiro: this.inputValorJaneiro != null ? this.inputValorJaneiro.replace("R$", "") : "",
        pr_fevereiro: this.inputValorFevereiro != null ? this.inputValorFevereiro.replace("R$", "") : "",
        capacidade: this.inputMaxPessoas != null ? this.inputMaxPessoas : "",
        imagens: this.itensImagens != null ? this.itensImagens : [],
        tipo: this.selectedTipo,
        categoria: this.selectedNegocio,
        id_categoria: this.converCategoria(this.selectedNegocio),
        ocupacao: this.inputOcupacao != null ? this.inputOcupacao : "",
        thumb: this.selectedThumb != null ? this.selectedThumb : "",
        proprietario: this.inputProprietario != null ? this.inputProprietario : "",
        cidade: this.inputCidade != null ? this.inputCidade : "",
        contatoCelular: this.inputContatoCelular != null ? this.inputContatoCelular : "",
        contatoFixo: this.inputContatoFixo != null ? this.inputContatoFixo : "",
        enderecoImovel: this.inputEnderecoImovel != null ? this.inputEnderecoImovel : "",
        id: id
      })

      this.router.navigate(['/gerenciarimovel/', id]);
    }
  }

  selectTipo(tipo: number) {
    this.selectedTipo = tipo;
  }

  selectNegocio(negocio: string) {
    this.selectedNegocio = negocio;
  }

  converCategoria(categoria: String): string {
    switch (categoria) {
      case "Aluguel Anual":
        return "AA";
      case "Aluguel Temporada":
        return "AT";
      case "Empreendimento":
        return "LA";
      case "Venda":
        return "VE";
      default: "AT";
    }
  }

  ngOnInit() {
    this.itensImagens = [];

    this.firebaseService.getMaxCodigo()
      .subscribe(result => {
        if (result.length > 0) {
          this.inputCodigo = String(parseInt(result[0].payload.doc.data()['id_imovel']) + 1);
        }
      });
  }

  public open(event, item) {
    if (item !== -1) {
      this.itensImagens.splice(item, 1);
    }
  }

  public removerThumb(event, item) {
    this.selectedThumb = "";
  }

  upload(event) {
    const id = Math.random().toString(36).substring(2);
    this.ref = this.afStorage.ref(id);
    this.task = this.ref.put(event.target.files[0]);

    this.task.snapshotChanges().pipe(
      finalize(() => {
        this.ref.getDownloadURL().subscribe(url => {
          console.log(url);

          this.itensImagens.push({ path: url });

        });
      })
    ).subscribe();

  }

  uploadThumb(event) {
    const id = Math.random().toString(36).substring(2);
    this.ref = this.afStorage.ref(id);
    this.task = this.ref.put(event.target.files[0]);

    this.task.snapshotChanges().pipe(
      finalize(() => {
        this.ref.getDownloadURL().subscribe(url => {
          console.log(url);

          this.selectedThumb = url;
        });
      })
    ).subscribe();

  }

  logout() {
    this.authService.doLogout()
      .then((res) => {
        this.router.navigate(['/login']);
      }, (error) => {
        console.log("Logout error", error);
      });
  }

  

}
