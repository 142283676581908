import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { Router, Params } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    constructor(
        public authService: AuthService, public afAuth: AngularFireAuth, private router: Router,
    ) {
    }

    ngOnInit() {
      
    }

    tryLogin(email:string, senha:string){
        this.authService.doLogin(email, senha)
        .then(res => {
          this.router.navigate(['/gerenciador']);
        }, err => {
          console.log(err);
          alert(err.message);
        })
      }

}
