import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AuthService, private router: Router,
    public afAuth: AngularFireAuth
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.afAuth.authState.map(auth => {
      if (auth == null || auth == undefined) {
        this.router.navigate(['/login']);
        return false;
      } else {
        return true;
      }
    });
  }

  /*canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {


    this.afAuth.auth.onAuthStateChanged(async (user) => {

      if (user != null) {
        alert("USUARIO LOGADO!");
        return true;
      } else {
        alert("Acesso bloqueado!");
        console.log('access denied!')
        this.router.navigate(['/login']);
        return false
      }
    });

    alert("ENTROU AQUI!");
    return false;
    /*var user = this.afAuth.auth.currentUser;

    if (user) {
      return true;
    } else {
      alert("Acesso bloqueado!");
      console.log('access denied!')
      this.router.navigate(['/login']);
      return false
    }

  }*/
}
