import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../../services/firebase.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-basicelements',
  templateUrl: './basicelements.component.html',
  styleUrls: ['./basicelements.component.scss']
})
export class BasicelementsComponent implements OnInit {
  selectedNegocioOption: string;
  selectedTipoOption: string;
  id_imovel: String;

  selectedTipo;
  selectedNegocio;

  itensNegocio: any[] = [
    { id: 1, negocio: 'Aluguel Anual' },
    { id: 2, negocio: 'Aluguel Temporada' },
    { id: 3, negocio: 'Empreendimento' },
    { id: 4, negocio: 'Venda' }
  ];

  itensTipo: any[] = [
    { id: 1, tipo: 'Apartamento' },
    { id: 2, tipo: 'Casa' },
    { id: 3, tipo: 'Casa Comercial' },
    { id: 4, tipo: 'Casa Geminada' },
    { id: 5, tipo: 'Chácara' },
    { id: 6, tipo: 'Cobertura' },
    { id: 7, tipo: 'Kitnete' },
    { id: 8, tipo: 'Loja' },
    { id: 9, tipo: 'Pavilhão' },
    { id: 10, tipo: 'Pousada' },
    { id: 11, tipo: 'Prédio Comercial' },
    { id: 12, tipo: 'Sala' },
    { id: 13, tipo: 'Sítio' },
    { id: 14, tipo: 'Terreno' },
    { id: 15, tipo: 'Terreo Comercial' },
    { id: 16, tipo: 'Terreno em condominio' },
    { id: 17, tipo: 'Terrano em loteamento' },
  ];

  selectTipo(tipo: number) {
    if (tipo != 0) {
      this.selectedTipo = tipo;
    }
  }

  selectNegocio(negocio: string) {
    switch (negocio) {
      case "Aluguel Anual":
        this.selectedNegocio = "AA";
        break;
      case "Aluguel Temporada":
        this.selectedNegocio = "AT";
        break;
      case "Empreendimento":
        this.selectedNegocio = "LA";
        break;
      case "Venda":
        this.selectedNegocio = "VE";
        break;
      default: "AT";
    }
  }


  //constructor() { }
  itensAluguelTemporada: Array<any>;
  itensLancamentos: Array<any>;
  itensImoveisVenda: Array<any>;
  itensImoveisEncontrados: Array<any>;

  constructor(private router: Router, public firebaseService: FirebaseService) {
  }

  ngOnInit() {
    this.firebaseService.getHomeAlugueisTemporada()
      .subscribe(result => {
        this.itensAluguelTemporada = result;
      });

    this.firebaseService.getHomeLancamentos()
      .subscribe(result => {
        this.itensLancamentos = result;
      });

    this.firebaseService.getHomeImoveisVenda()
      .subscribe(result => {
        this.itensImoveisVenda = result;
      });
  }

  onPageImovel() {
    if (this.id_imovel != null) {
      this.firebaseService.getPesquisaCodigo(this.id_imovel)
        .subscribe(result => {
          this.itensImoveisEncontrados = result;

          if (this.itensImoveisEncontrados.length > 0) {
            this.id_imovel = this.itensImoveisEncontrados[0].payload.doc.id;


            this.router.navigate([`/house/${this.itensImoveisEncontrados[0].payload.doc.id}`]);
          } else {
            alert("Nenhum imóvel encontrado!");
          }

        });
    }
  }

  verificaThumb(thumb: String): String {
    if(thumb == null || thumb == ''){
      return "https://firebasestorage.googleapis.com/v0/b/portoalegrense-50728.appspot.com/o/sem_imagem.jpg?alt=media&token=a0b3cae5-8212-4773-ac11-c13d5ed6bdf3";
    }else{
      return thumb;
    }
  }

  convertTipo(id_tipo: String) :String{
    switch (id_tipo) {
      case '1':
        return "Apartamento";
      case '2':
        return "Casa";
      case '3':
        return "Casa Comercial";
      case '4':
        return "Casa Geminada";
      case '5':
        return "Chácara";
      case '6':
        return "Cobertura";
      case '7':
        return "Kitnete";
      case '8':
        return "Loja";
      case '9':
        return "Pavilhão";
      case '10':
        return "Pousada";
      case '11':
        return "Prédio Comercial";
      case '12':
        return "Sala";
      case '13':
        return "Sítio";
      case '14':
        return "Terreno";
      case '15':
        return "Terreo Comercial";
      case '16':
        return "Terreno em condominio";
      case '17':
        return "Terrano em loteamento";
      default: "";
    }
  }

  convertValor(valor: String) {
    if(valor != ""){
      return "R$" + valor;
    }else{
      return "Sob consulta";
    }
  }

}
