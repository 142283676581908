import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    selectedNegocioOption: string;
    selectedTipoOption: string;

    optionsTipo = [
        { name: "Tipo", value: 1 },
        { name: "option2", value: 2 }
    ]

    optionsNegocio = [
        { name: "Negócio", value: 1 },
        { name: "option2", value: 2 }
    ]

    print() {
        this.selectedNegocioOption;
    }

    constructor(private router: Router) {
    }

    ngOnInit() {
    }

}
