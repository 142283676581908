// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDOsWlZAiSQJ7P-E-sHxEKjbB5b2hw-aBc",
    authDomain: "portoalegrense-50728.firebaseapp.com",
    databaseURL: "https://portoalegrense-50728.firebaseio.com",
    projectId: "portoalegrense-50728",
    storageBucket: "portoalegrense-50728.appspot.com",
    messagingSenderId: "898580647202",
    appId: "1:898580647202:web:609d543d796ccd6c"
  },
};
