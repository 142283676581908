import { Component, OnInit, Inject, Renderer} from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

    constructor( private renderer : Renderer,  @Inject(DOCUMENT,) public location: Location) {}
    
    ngOnInit() {
        this.renderer.listenGlobal('window', 'scroll', null);

    }
}
