import { Component, OnInit, Input } from '@angular/core';
import { FirebaseService } from '../../services/firebase.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { catchError, finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { AuthService } from "../../core/auth.service";

@Component({
  selector: 'app-gerenciar-imovel',
  templateUrl: './gerenciar_imovel.component.html',
  styleUrls: ['./gerenciar_imovel.component.scss']
})
export class GerenciarImovelComponent implements OnInit {

  id_house: string;
  private sub: any;
  itens: any;

  inputTitulo: String;
  inputCodigo: String;
  inputValor: String;
  inputBairro: String;
  inputDescricao: String;
  inputInfraestrutura: String;
  inputDormitorios: String;
  inputBanheiros: String;
  inputGaragem: String;
  inputAcomodacoes: String;
  inputMUtil: String;
  inputMTotal: String;
  inputValorFestas: String;
  inputValorJaneiro: String;
  inputValorFevereiro: String;
  inputMaxPessoas: String;
  inputOcupacao: String;
  selectedThumb: String;
  inputProprietario: String;
  inputCidade: String;
  inputContatoCelular: String;
  inputContatoFixo: String;
  inputEnderecoImovel: String;

  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  imageUrl: String;
  itensImagens: Array<any>;
  selectedTipo;
  selectedNegocio;

  itensTipo: any[] = [
    { id: 1, tipo: 'Apartamento' },
    { id: 2, tipo: 'Casa' },
    { id: 3, tipo: 'Casa Comercial' },
    { id: 4, tipo: 'Casa Geminada' },
    { id: 5, tipo: 'Chácara' },
    { id: 6, tipo: 'Cobertura' },
    { id: 7, tipo: 'Kitnete' },
    { id: 8, tipo: 'Loja' },
    { id: 9, tipo: 'Pavilhão' },
    { id: 10, tipo: 'Pousada' },
    { id: 11, tipo: 'Prédio Comercial' },
    { id: 12, tipo: 'Sala' },
    { id: 13, tipo: 'Sítio' },
    { id: 14, tipo: 'Terreno' },
    { id: 15, tipo: 'Terreo Comercial' },
    { id: 16, tipo: 'Terreno em condominio' },
    { id: 17, tipo: 'Terrano em loteamento' },
  ];

  itensNegocio: any[] = [
    { id: 1, negocio: 'Aluguel Anual' },
    { id: 2, negocio: 'Aluguel Temporada' },
    { id: 3, negocio: 'Empreendimento' },
    { id: 4, negocio: 'Venda' }
  ];

  constructor(private route: ActivatedRoute, private router: Router,
    public firebaseService: FirebaseService, private afStorage: AngularFireStorage, public db: AngularFirestore,
    public authService: AuthService) {
  }

  onSubmit() {
    this.db.collection("imoveis").doc(this.id_house).set({
      titulo: this.inputTitulo != null ? this.inputTitulo : "",
      id_imovel: this.inputCodigo != null ? String(this.inputCodigo) : "",
      valor: this.inputValor != null ? this.inputValor : "",
      bairro: this.inputBairro != null ? this.inputBairro : "",
      descricao: this.inputDescricao != null ? this.inputDescricao : "",
      infraestrutura: this.inputInfraestrutura != null ? this.inputInfraestrutura : "",
      dormitorio: this.inputDormitorios != null ? this.inputDormitorios : "",
      banheiro: this.inputBanheiros != null ? this.inputBanheiros : "",
      garagem: this.inputGaragem != null ? this.inputGaragem : "",
      acomodacoes: this.inputAcomodacoes != null ? this.inputAcomodacoes : "",
      mutil: this.inputMUtil != null ? this.inputMUtil : "",
      mtotal: this.inputMTotal != null ? this.inputMTotal : "",
      pr_festas: this.inputValorFestas != null ? this.inputValorFestas : "",
      pr_janeiro: this.inputValorJaneiro != null ? this.inputValorJaneiro : "",
      pr_fevereiro: this.inputValorFevereiro != null ? this.inputValorFevereiro : "",
      capacidade: this.inputMaxPessoas != null ? this.inputMaxPessoas : "",
      imagens: this.itensImagens != null ? this.itensImagens : [],
      tipo: this.selectedTipo,
      categoria: this.selectedNegocio,
      id_categoria: this.converCategoria(this.selectedNegocio),
      ocupacao: this.inputOcupacao != null ? this.inputOcupacao : "",
      thumb: this.selectedThumb != null ? this.selectedThumb : "",
      proprietario: this.inputProprietario != null ? this.inputProprietario : "",
      cidade: this.inputCidade != null ? this.inputCidade : "",
      contatoCelular: this.inputContatoCelular != null ? this.inputContatoCelular : "",
      contatoFixo: this.inputContatoFixo != null ? this.inputContatoFixo : "",
      enderecoImovel: this.inputEnderecoImovel != null ? this.inputEnderecoImovel : "",
    }, { merge: true })

    alert("Salvo com sucesso!");
    //this.router.navigate(['/gerenciador']);
  }

  selectTipo(tipo: number) {
    if (tipo != 0) {
      this.selectedTipo = tipo;
    }
  }

  selectNegocio(negocio: string) {
    this.selectedNegocio = negocio;
  }

  converCategoria(categoria: String): string {
    switch (categoria) {
      case "Aluguel Anual":
        return "AA";
      case "Aluguel Temporada":
        return "AT";
      case "Empreendimento":
        return "LA";
      case "Venda":
        return "VE";
      default: "AT";
    }
  }

  ngOnInit() {
    this.itensImagens = [];

    this.sub = this.route.params.subscribe(params => {
      this.id_house = params['id'];
    });

    this.firebaseService.getHomeDetail(this.id_house)
      .subscribe(result => {
        this.itens = result;
        this.inputTitulo = this.itens.payload.data().titulo;
        this.inputCodigo = String(this.itens.payload.data().id_imovel);
        this.inputValor = this.itens.payload.data().valor;
        this.inputBairro = this.itens.payload.data().bairro;
        this.inputDescricao = this.itens.payload.data().descricao;
        this.inputInfraestrutura = this.itens.payload.data().infraestrutura;
        this.inputDormitorios = this.itens.payload.data().dormitorio;
        this.inputBanheiros = this.itens.payload.data().banheiro;
        this.inputGaragem = this.itens.payload.data().garagem;
        this.inputAcomodacoes = this.itens.payload.data().acomodacoes;
        this.inputMUtil = this.itens.payload.data().mutil;
        this.inputMTotal = this.itens.payload.data().mtotal;
        this.inputValorFestas = this.itens.payload.data().pr_festas;
        this.inputValorJaneiro = this.itens.payload.data().pr_janeiro;
        this.inputValorFevereiro = this.itens.payload.data().pr_fevereiro;
        this.inputMaxPessoas = this.itens.payload.data().capacidade;
        this.itensImagens = this.itens.payload.data().imagens;
        this.selectedTipo = this.itens.payload.data().tipo;
        this.selectedNegocio = this.itens.payload.data().categoria;
        this.inputOcupacao = this.itens.payload.data().ocupacao;
        this.selectedThumb = this.itens.payload.data().thumb;
        this.inputProprietario = this.itens.payload.data().proprietario;
        this.inputCidade = this.itens.payload.data().cidade;
        this.inputContatoCelular = this.itens.payload.data().contatoCelular;
        this.inputContatoFixo = this.itens.payload.data().contatoFixo;
        this.inputEnderecoImovel = this.itens.payload.data().enderecoImovel;
      });


  }

  public open(event, item) {
    if (confirm("Deseja realmente excluir esta imagem?")) {
      if (item !== -1) {
        this.itensImagens.splice(item, 1);
      }
    }
  }

  upload(event) {
    const id = Math.random().toString(36).substring(2);
    this.ref = this.afStorage.ref(id);
    this.task = this.ref.put(event.target.files[0]);

    this.task.snapshotChanges().pipe(
      finalize(() => {
        this.ref.getDownloadURL().subscribe(url => {
          console.log(url);

          this.itensImagens.push({ path: url });

          /*new Date()
          this.db
            .collection('imoveis')
            .doc('-LnrJDDE7A-xHsqyuDrS')
            .set(
              { imagens: [{ path: url }] },
              { merge: true }
            )*/

        });
      })
    ).subscribe();

  }

  clickMethod(name: string) {
    if (confirm("Deseja realmente excluir este imóvel?")) {
      this.firebaseService.deleteImovel(this.id_house);
      this.router.navigate(['/gerenciador']);
    }
  }

  logout() {
    this.authService.doLogout()
      .then((res) => {
        this.router.navigate(['/login']);
      }, (error) => {
        console.log("Logout error", error);
      });
  }

  convertTipo(id_tipo: String) {
    switch (id_tipo) {
      case '1':
        return "Apartamento";
      case '2':
        return "Casa";
      case '3':
        return "Casa Comercial";
      case '4':
        return "Casa Geminada";
      case '5':
        return "Chácara";
      case '6':
        return "Cobertura";
      case '7':
        return "Kitnete";
      case '8':
        return "Loja";
      case '9':
        return "Pavilhão";
      case '10':
        return "Pousada";
      case '11':
        return "Prédio Comercial";
      case '12':
        return "Sala";
      case '13':
        return "Sítio";
      case '14':
        return "Terreno";
      case '15':
        return "Terreo Comercial";
      case '16':
        return "Terreno em condominio";
      case '17':
        return "Terrano em loteamento";
      default: "";
    }
  }

  public removerThumb(event, item) {
    if (confirm("Deseja realmente excluir esta imagem?")) {
      this.selectedThumb = "";
    }
  }

  uploadThumb(event) {
    const id = Math.random().toString(36).substring(2);
    this.ref = this.afStorage.ref(id);
    this.task = this.ref.put(event.target.files[0]);

    this.task.snapshotChanges().pipe(
      finalize(() => {
        this.ref.getDownloadURL().subscribe(url => {
          console.log(url);

          this.selectedThumb = url;
        });
      })
    ).subscribe();

  }

}
