import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FirebaseService } from '../../services/firebase.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-aluguelanual',
  templateUrl: './aluguelanual.component.html',
  styleUrls: ['./aluguelanual.component.scss']
})

export class AluguelAnualComponent implements OnInit {

  itensAluguelAnual: Array<any>;
  config: any;


  /*constructor(private router: Router, public firebaseService: FirebaseService) {
  }*/

  /*ngOnInit() {
    this.firebaseService.getListAlugueisAnual()
      .subscribe(result => {
        this.itensAluguelAnual = result;
      });
  }*/

  //Models for Input fields
  nameValue: string;
  placeValue: string;

  //Data object for listing items
  tableData: any[] = [];

  //Save first document in snapshot of items received
  firstInResponse: any = [];

  //Save last document in snapshot of items received
  lastInResponse: any = [];

  //Keep the array of first document of previous pages
  prev_strt_at: any = [];

  //Maintain the count of clicks on Next Prev button
  pagination_clicked_count = 0;

  //Disable next and prev buttons
  disable_next: boolean = false;
  disable_prev: boolean = false;

  constructor(
    private firestore: AngularFirestore, private router: Router
  ) {
    this.loadItems();
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }


  loadItems() {
    this.firestore.collection('imoveis', ref => ref
      .where("id_categoria", "==", "AA")
      .limit(16)
    ).snapshotChanges()
      .subscribe(response => {
        if (!response.length) {
          console.log("No Data Available");
          return false;
        }
        this.firstInResponse = response[0].payload.doc;
        this.lastInResponse = response[response.length - 1].payload.doc;

        this.tableData = [];
        for (let item of response) {
          this.tableData.push(item.payload.doc.data());
        }

        //Initialize values
        this.prev_strt_at = [];
        this.pagination_clicked_count = 0;
        this.disable_next = false;
        this.disable_prev = false;

        //Push first item to use for Previous action
        this.push_prev_startAt(this.firstInResponse);
      }, error => {
      });
  }

  //Show previous set 
  prevPage() {
    this.disable_prev = true;
    this.firestore.collection('imoveis', ref => ref
      .where("id_categoria", "==", "AA")
      .startAt(this.get_prev_startAt())
      .endBefore(this.firstInResponse)
      .limit(16)
    ).get()
      .subscribe(response => {
        this.firstInResponse = response.docs[0];
        this.lastInResponse = response.docs[response.docs.length - 1];

        this.tableData = [];
        for (let item of response.docs) {
          this.tableData.push(item.data());
        }

        //Maintaing page no.
        this.pagination_clicked_count--;

        //Pop not required value in array
        this.pop_prev_startAt(this.firstInResponse);

        //Enable buttons again
        this.disable_prev = false;
        this.disable_next = false;
      }, error => {
        this.disable_prev = false;
      });
  }

  nextPage() {
    this.disable_next = true;
    this.firestore.collection('imoveis', ref => ref
      .where("id_categoria", "==", "AA")
      .limit(16)
      .startAfter(this.lastInResponse)
    ).get()
      .subscribe(response => {

        if (!response.docs.length) {
          this.disable_next = true;
          return;
        }

        this.firstInResponse = response.docs[0];

        this.lastInResponse = response.docs[response.docs.length - 1];
        this.tableData = [];
        for (let item of response.docs) {
          this.tableData.push(item.data());
        }

        this.pagination_clicked_count++;

        this.push_prev_startAt(this.firstInResponse);

        this.disable_next = false;
      }, error => {
        this.disable_next = false;
      });
  }

  //Add document
  push_prev_startAt(prev_first_doc) {
    this.prev_strt_at.push(prev_first_doc);
  }

  //Remove not required document 
  pop_prev_startAt(prev_first_doc) {
    this.prev_strt_at.forEach(element => {
      if (prev_first_doc.data().id == element.data().id) {
        element = null;
      }
    });
  }

  //Return the Doc rem where previous page will startAt
  get_prev_startAt() {
    if (this.prev_strt_at.length > (this.pagination_clicked_count + 1))
      this.prev_strt_at.splice(this.prev_strt_at.length - 2, this.prev_strt_at.length - 1);
    return this.prev_strt_at[this.pagination_clicked_count - 1];
  }

  verificaThumb(thumb: String): String {
    if (thumb == null || thumb == '') {
      return "https://firebasestorage.googleapis.com/v0/b/portoalegrense-50728.appspot.com/o/sem_imagem.jpg?alt=media&token=a0b3cae5-8212-4773-ac11-c13d5ed6bdf3";
    } else {
      return thumb;
    }
  }

  convertTipo(id_tipo: String): String {
    switch (id_tipo) {
      case '1':
        return "Apartamento";
      case '2':
        return "Casa";
      case '3':
        return "Casa Comercial";
      case '4':
        return "Casa Geminada";
      case '5':
        return "Chácara";
      case '6':
        return "Cobertura";
      case '7':
        return "Kitnete";
      case '8':
        return "Loja";
      case '9':
        return "Pavilhão";
      case '10':
        return "Pousada";
      case '11':
        return "Prédio Comercial";
      case '12':
        return "Sala";
      case '13':
        return "Sítio";
      case '14':
        return "Terreno";
      case '15':
        return "Terreo Comercial";
      case '16':
        return "Terreno em condominio";
      case '17':
        return "Terrano em loteamento";
      default: "";
    }
  }

  convertValor(valor: String) {
    if (valor != "") {
      return "R$" + valor;
    } else {
      return "Sob consulta";
    }
  }

}
