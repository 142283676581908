import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseService } from '../../services/firebase.service';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-pesquisa',
  templateUrl: './pesquisa.component.html',
  styleUrls: ['./pesquisa.component.scss']
})

export class PesquisaComponent implements OnInit {
  selectedNegocio: string;
  selectedTipo: string;
  selectedQuartos: string;
  selectedBairro: string;

  //Models for Input fields
  nameValue: string;
  placeValue: string;

  //Data object for listing items
  tableData: any[] = [];

  //Save first document in snapshot of items received
  firstInResponse: any = [];

  //Save last document in snapshot of items received
  lastInResponse: any = [];

  //Keep the array of first document of previous pages
  prev_strt_at: any = [];

  //Maintain the count of clicks on Next Prev button
  pagination_clicked_count = 0;

  //Disable next and prev buttons
  disable_next: boolean = false;
  disable_prev: boolean = false;

  optionsTipo: any[] = [
    { id: 1, name: 'Apartamento' },
    { id: 2, name: 'Casa' },
    { id: 3, name: 'Casa Comercial' },
    { id: 4, name: 'Casa Geminada' },
    { id: 5, name: 'Chácara' },
    { id: 6, name: 'Cobertura' },
    { id: 7, name: 'Kitnete' },
    { id: 8, name: 'Loja' },
    { id: 9, name: 'Pavilhão' },
    { id: 10, name: 'Pousada' },
    { id: 11, name: 'Prédio Comercial' },
    { id: 12, name: 'Sala' },
    { id: 13, name: 'Sítio' },
    { id: 14, name: 'Terreno' },
    { id: 15, name: 'Terreo Comercial' },
    { id: 16, name: 'Terreno em condominio' },
    { id: 17, name: 'Terrano em loteamento' },
  ];

  optionsNegocio: any[] = [
    { id: 1, name: 'Aluguel Anual' },
    { id: 2, name: 'Aluguel Temporada' },
    { id: 3, name: 'Empreendimento' },
    { id: 4, name: 'Venda' }
  ];

  optionsQuartos: any[] = [
    { id: 1, name: '1 dormitório' },
    { id: 2, name: '2 dormitórios' },
    { id: 3, name: '3 dormitórios ou mais' }
  ];

  optionsBairros: any[] = [
    { id: 1, name: 'PRAIA GRANDE' },
    { id: 2, name: 'PRAIA DA CAL' },
    { id: 3, name: 'PRAINHA' },
    { id: 4, name: 'GUARITA' },
    { id: 5, name: 'CENTRO' },
    { id: 6, name: 'IGRA NORTE' },
    { id: 7, name: 'IGRA SUL' },
    { id: 8, name: 'GETÚLIO VARGAS' },
    { id: 9, name: 'VILA SÃO JOÃO' },
    { id: 10, name: 'CENTENÁRIO' },
    { id: 11, name: 'FAXINAL' }
  ];

  tipo: String;
  negocio: String;
  quartos: String;
  bairro: String;

  private sub: any;
  itensPesquisa: Array<any>;

  constructor(private route: ActivatedRoute, public firebaseService: FirebaseService, private firestore: AngularFirestore) {
    this.sub = this.route.params.subscribe(params => {
      this.tipo = params['tipo'];
      this.negocio = params['negocio'];
      this.quartos = params['quartos'];
      this.bairro = params['bairro'];
      this.loadItems();
    });
    
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  selectTipo(value: string) {
    if(value != '0'){
      this.selectedTipo = value;
    }else{
      this.selectedTipo = undefined;
    }
  }

  selectNegocio(value: string) {
    this.selectedNegocio = this.converCategoria(value);
  }

  selectQuartos(value: string) {
    this.selectedQuartos = value.replace(/\D/g, '');
  }

  selectBairro(value: string) {
    this.selectedBairro = value;
  }

  loadItems() {
    this.firestore.collection('imoveis', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (this.tipo != 'undefined') { query = query.where('tipo', '==', this.tipo) };
      if (this.negocio != 'undefined') { query = query.where('id_categoria', '==', this.negocio) };
      if (this.quartos != 'undefined') { query = query.where('dormitorio', '==', this.quartos) };
      if (this.bairro != 'undefined') { query = query.where('bairro', '==', this.bairro) };
      query = query.limit(16);
      return query;
    }).snapshotChanges()
      .subscribe(response => {
        if (!response.length) {
          console.log("No Data Available");
          this.tableData = [];
          return false;
        }
        this.firstInResponse = response[0].payload.doc;
        this.lastInResponse = response[response.length - 1].payload.doc;

        this.tableData = [];
        for (let item of response) {
          this.tableData.push(item.payload.doc.data());
        }

        //Initialize values
        this.prev_strt_at = [];
        this.pagination_clicked_count = 0;
        this.disable_next = false;
        this.disable_prev = false;

        //Push first item to use for Previous action
        this.push_prev_startAt(this.firstInResponse);
      }, error => {
      });
  }

  //Show previous set 
  prevPage() {
    this.disable_prev = true;
    this.firestore.collection('imoveis',
      ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        if (this.tipo != 'undefined') { query = query.where('tipo', '==', this.tipo) };
        if (this.negocio != 'undefined') { query = query.where('id_categoria', '==', this.negocio) };
        if (this.quartos != 'undefined') { query = query.where('dormitorio', '==', this.quartos) };
        if (this.bairro != 'undefined') { query = query.where('bairro', '==', this.bairro) };
        query = query.limit(16)
          .startAt(this.get_prev_startAt())
          .endBefore(this.firstInResponse);
        return query;
      }
    ).get()
      .subscribe(response => {
        this.firstInResponse = response.docs[0];
        this.lastInResponse = response.docs[response.docs.length - 1];

        this.tableData = [];
        for (let item of response.docs) {
          this.tableData.push(item.data());
        }

        //Maintaing page no.
        this.pagination_clicked_count--;

        //Pop not required value in array
        this.pop_prev_startAt(this.firstInResponse);

        //Enable buttons again
        this.disable_prev = false;
        this.disable_next = false;
      }, error => {
        this.disable_prev = false;
      });
  }

  nextPage() {
    this.disable_next = true;
    this.firestore.collection('imoveis', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (this.tipo != 'undefined') { query = query.where('tipo', '==', this.tipo) };
      if (this.negocio != 'undefined') { query = query.where('id_categoria', '==', this.negocio) };
      if (this.quartos != 'undefined') { query = query.where('dormitorio', '==', this.quartos) };
      if (this.bairro != 'undefined') { query = query.where('bairro', '==', this.bairro) };
      query = query.limit(16).startAfter(this.lastInResponse);
      return query;
    }).get()
      .subscribe(response => {

        if (!response.docs.length) {
          this.disable_next = true;
          return;
        }

        this.firstInResponse = response.docs[0];

        this.lastInResponse = response.docs[response.docs.length - 1];
        this.tableData = [];
        for (let item of response.docs) {
          this.tableData.push(item.data());
        }

        this.pagination_clicked_count++;

        this.push_prev_startAt(this.firstInResponse);

        this.disable_next = false;
      }, error => {
        this.disable_next = false;
      });
  }

  //Add document
  push_prev_startAt(prev_first_doc) {
    this.prev_strt_at.push(prev_first_doc);
  }

  //Remove not required document 
  pop_prev_startAt(prev_first_doc) {
    this.prev_strt_at.forEach(element => {
      if (prev_first_doc.data().id == element.data().id) {
        element = null;
      }
    });
  }

  //Return the Doc rem where previous page will startAt
  get_prev_startAt() {
    if (this.prev_strt_at.length > (this.pagination_clicked_count + 1))
      this.prev_strt_at.splice(this.prev_strt_at.length - 2, this.prev_strt_at.length - 1);
    return this.prev_strt_at[this.pagination_clicked_count - 1];
  }

  convertTipo(id_tipo: String) {
    switch (id_tipo) {
      case '1':
        return "Apartamento";
      case '2':
        return "Casa";
      case '3':
        return "Casa Comercial";
      case '4':
        return "Casa Geminada";
      case '5':
        return "Chácara";
      case '6':
        return "Cobertura";
      case '7':
        return "Kitnete";
      case '8':
        return "Loja";
      case '9':
        return "Pavilhão";
      case '10':
        return "Pousada";
      case '11':
        return "Prédio Comercial";
      case '12':
        return "Sala";
      case '13':
        return "Sítio";
      case '14':
        return "Terreno";
      case '15':
        return "Terreo Comercial";
      case '16':
        return "Terreno em condominio";
      case '17':
        return "Terrano em loteamento";
      default: "";
    }
  }

  convertValor(valor: String) {
    if (valor != "") {
      return "R$" + valor;
    } else {
      return "Sob consulta";
    }
  }

  converCategoria(categoria: String): string {
    switch (categoria) {
      case "Aluguel Anual":
        return "AA";
      case "Aluguel Temporada":
        return "AT";
      case "Empreendimento":
        return "LA";
      case "Venda":
        return "VE";
      default: "AT";
    }
  }

  convertIdToCategoria(categoria: String): string {
    switch (categoria) {
      case "AA":
        return "Aluguel Anual";
      case "AT":
        return "Aluguel Temporada";
      case "LA":
        return "Empreendimento";
      case "VE":
        return "Venda";
      default: "AT";
    }
  }

  verificaThumb(thumb: String): String {
    if (thumb == null || thumb == '') {
      return "https://firebasestorage.googleapis.com/v0/b/portoalegrense-50728.appspot.com/o/sem_imagem.jpg?alt=media&token=a0b3cae5-8212-4773-ac11-c13d5ed6bdf3";
    } else {
      return thumb;
    }
  }

  convertMaxPessoas(value: String):String{
    if(value == null || value == ''){
      return "";
    }else{
      return value + " máx. de pessoas";
    }
  }
}
