import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { RouterModule } from '@angular/router';

import { BasicelementsComponent } from './basicelements/basicelements.component';
import { NavigationComponent } from './navigation/navigation.component';
import { TypographyComponent } from './typography/typography.component';
import { NucleoiconsComponent } from './nucleoicons/nucleoicons.component';
import { ComponentsComponent } from './components.component';
import { NotificationComponent } from './notification/notification.component';
import { NgbdModalBasic } from './modal/modal.component';
import { HouseDetailComponent} from './housedetail/housedetail.component';
import { AluguelTemporadaComponent} from './alugueltemporada/alugueltemporada.component';
import { VendaComponent} from './venda/venda.component';
import { LancamentoComponent } from './lancamento/lancamento.component';
import { AluguelAnualComponent } from './aluguelanual/aluguelanual.component';
import { ContatoComponent } from './contato/contato.component';
import { PesquisaComponent } from './pesquisa/pesquisa.component';
import { HttpClientModule } from '@angular/common/http';
import { GerenciadorComponent } from './gerenciador/gerenciador.component';
import { GerenciarImovelComponent } from './gerenciar_imovel/gerenciar_imovel.component';
import { CadastrarImovelComponent } from './cadastrar_imovel/cadastrar_imovel.component';
import { AuthGuard} from "../core/auth.guard";
import { LightboxModule } from 'ngx-lightbox';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NouisliderModule,
        RouterModule,
        JwBootstrapSwitchNg2Module,
        HttpClientModule,
        LightboxModule 
      ],
    declarations: [
        ComponentsComponent,
        BasicelementsComponent,
        NavigationComponent,
        TypographyComponent,
        NucleoiconsComponent,
        NotificationComponent,
        NgbdModalBasic,
        HouseDetailComponent,
        AluguelTemporadaComponent,
        VendaComponent,
        LancamentoComponent,
        AluguelAnualComponent,
        ContatoComponent,
        PesquisaComponent,
        GerenciadorComponent,
        GerenciarImovelComponent,
        CadastrarImovelComponent
    ],
    providers: [AuthGuard],
    exports:[ ComponentsComponent ]
})
export class ComponentsModule { }
