import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { LandingComponent } from './examples/landing/landing.component';
import { LoginComponent } from './examples/login/login.component';
import { ProfileComponent } from './examples/profile/profile.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { HouseDetailComponent } from './components/housedetail/housedetail.component';
import { AluguelTemporadaComponent } from './components/alugueltemporada/alugueltemporada.component';
import { VendaComponent } from './components/venda/venda.component';
import { LancamentoComponent } from './components/lancamento/lancamento.component';
import { AluguelAnualComponent } from './components/aluguelanual/aluguelanual.component';
import { ContatoComponent } from './components/contato/contato.component';
import { PesquisaComponent } from './components/pesquisa/pesquisa.component';
import { GerenciadorComponent } from './components/gerenciador/gerenciador.component';
import { GerenciarImovelComponent } from './components/gerenciar_imovel/gerenciar_imovel.component';
import { CadastrarImovelComponent } from './components/cadastrar_imovel/cadastrar_imovel.component';
import { AuthGuard } from '../app/core/auth.guard';

const routes: Routes = [
    { path: '', redirectTo: 'index', pathMatch: 'full' },
    { path: 'index', component: ComponentsComponent },
    //{ path: 'index', component: LoginComponent },    
    { path: 'nucleoicons', component: NucleoiconsComponent },
    { path: 'examples/landing', component: LandingComponent },
    { path: 'login', component: LoginComponent},
    { path: 'examples/profile', component: ProfileComponent },
    { path: 'house/:id', component: HouseDetailComponent },
    { path: 'alugueltemporada', component: AluguelTemporadaComponent },
    { path: 'venda', component: VendaComponent },
    { path: 'lancamento', component: LancamentoComponent },
    { path: 'aluguelanual', component: AluguelAnualComponent },
    { path: 'contato', component: ContatoComponent },
    { path: 'pesquisa/:tipo/:negocio/:quartos/:bairro', component: PesquisaComponent },
    { path: 'gerenciador', component: GerenciadorComponent, canActivate: [AuthGuard]},
    { path: 'gerenciarimovel/:id', component: GerenciarImovelComponent, canActivate: [AuthGuard] },
    { path: 'cadastrarimovel', component: CadastrarImovelComponent, canActivate: [AuthGuard] }

];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
