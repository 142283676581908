import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from '../../services/firebase.service';

@Component({
    selector: 'app-contato',
    templateUrl: './contato.component.html',
    styleUrls: ['./contato.component.scss']
})
export class ContatoComponent implements OnInit {
  id_house: String;
  private sub: any;
  itens: any;
  inputNome: String;
  inputEmail: String;
  inputTelefone: String;
  inputMensagem: String;
  url: string;

  constructor(private route: ActivatedRoute, public firebaseService: FirebaseService) { }

  onSubmit() {

      this.url = "https://portoalegr16.sslblindado.com/util/sendmail.php?nome=" + this.inputNome
      + "&email=" + this.inputEmail
      + "&telefone=" + this.inputTelefone
      + "&mensagem=" + this.inputMensagem
      + "&cod_imovel=" + "PÁGINA DE CONTATO";

    window.open(this.url, "_blank").close();

    alert("Mensagem enviada!");
  }

  ngOnInit() { 
    
  }

}